<template>
  <header id="powerHead">
    <div class="p-header-container display-flex">
      <div class="display-flex flex-start">
        <router-link
          to="/"
          class="xpp-logo-icon"
          tag="div"
          rel="noopener"
        ></router-link>
        <router-link
          class="xpp-menus-nav-item"
          v-for="item in xppMenusNavList"
          :key="item.label"
          :to="item.to"
          rel="noopener"
          >{{ item.label }}</router-link
        >
      </div>
      <div class="xpp-menus-nav-passport display-flex">
        <a href="https://dev.x-canvas.net/dis/auth/login">登录</a>
        <a href="https://dev.x-canvas.net/dis/auth/login"
          ><el-button type="warning">注册</el-button></a
        >
      </div>
      <el-button
        class="xpp-menus-open-btn"
        icon="el-icon-menu"
        type="warning"
        size="mini"
        @click="xppMenusOpen = true"
      ></el-button>
    </div>
    <div
      class="xpp-menus-visible display-flex flex-center"
      :class="openMenusVisible"
    >
      <span
        class="xpp-menus-close-btn el-icon-close"
        @click="xppMenusOpen = false"
      ></span>
      <ol class="xpp-menus-nav-list" @click="routerJump">
        <li
          class="xpp-menus-nav-item"
          v-for="item in xppMenusNavList"
          :key="item.label"
          :data-to="item.to"
        >
          {{ item.label }}
        </li>
        <li class="xpp-menus-nav-item" data-to="/passport/login">
          登录
        </li>
        <li class="xpp-menus-nav-item" data-to="/passport/register">
          <el-button type="warning" class="events-none">注册</el-button>
        </li>
      </ol>
    </div>
  </header>
</template>

<script>
export default {
  computed: {
    openMenusVisible() {
      return {
        "menus-open": this.xppMenusOpen
      };
    }
  },
  data() {
    return {
      xppMenusOpen: false,
      xppMenusNavList: [
        {
          to: "/work-from-home",
          label: "在家办公第一季"
        },
        {
          to: "/scenarios",
          label: "应用场景"
        }
      ]
    };
  },
  methods: {
    routerJump({ target }) {
      console.log(target);
      if (target && target.dataset.to) {
        this.xppMenusOpen = false;
        this.$router.push(target.dataset.to);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#powerHead {
  position: relative;
  z-index: 2000;
  height: 60px;
  line-height: 60px;
  background-color: #2f2f2f;
  display: flex;
  justify-content: center;
}
.xpp-menus-nav-item {
  color: #fff;
  margin-right: 40px;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    color: #f99000;
  }
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    .el-button {
      background: #ebb563;
      border-color: #ebb563;
      color: #fff;
    }
  }
}
.p-header-container {
  flex: auto;
  max-width: 1170px;
  padding: 5px 30px;
  .xpp-menus-open-btn,
  .xpp-menus-visible {
    display: none;
  }
}
.xpp-menus-visible {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
  z-index: -1;
  overflow: hidden;
  transition: all 0.3s;
  pointer-events: none;
  background-color: #2f2f2f;
  text-align: center;
  color: #fff;
  &.menus-open {
    width: 100%;
    height: 100vh;
    opacity: 1;
    pointer-events: auto;
    z-index: 10001;
  }
  li {
    margin-right: 0;
  }
}
.xpp-menus-nav-list {
  padding: 0;
  .xpp-menus-nav-item {
    margin-right: 0;
  }
}
.xpp-menus-nav-passport {
  a {
    color: #fff;
    font-size: 14px;
  }
  a:first-child {
    cursor: pointer;
    margin-right: 30px;
    &:hover {
      color: #f99000;
    }
  }
}

@media (max-width: 960px) {
  .p-header-container {
    .xpp-menus-open-btn {
      display: inline-block;
    }
    .xpp-menus-nav-item,
    .xpp-menus-nav-passport {
      display: none;
    }
  }
}
.xpp-logo-icon {
  width: 34px;
  height: 34px;
  background: url(/img/ic_colored.png) no-repeat center;
  background-size: cover;
  cursor: pointer;
  margin-right: 94px;
}
.xpp-menus-close-btn {
  position: absolute;
  top: 24px;
  right: 24px;
  font-size: 32px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
}
.el-button--text {
  color: #fff;
}
</style>
