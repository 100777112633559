<template>
  <div id="errorContainer">
    <PowerHead />
    <div class="xpp-error-404">
      <h2>{{ message.title }}</h2>
      <p>{{ message.msg }}</p>
      <router-link :to="message.url" rel="noopener">
        <el-button round type="warning">{{ message.urlTit }}</el-button>
      </router-link>
    </div>
  </div>
</template>

<script>
import PowerHead from "@components/layout/PowerHead";

export default {
  name: "Product",
  components: { PowerHead },
  data() {
    return {
      message: {
        title: "错误的链接地址",
        msg: "请检查您输入的地址是否有误",
        url: "/",
        urlTit: "返回主页"
      }
    };
  },
  mounted() {
    // 在跳转页面前 保存XppErrMessage信息，会在此页面进行显示
    Object.assign(
      this.message,
      JSON.parse(localStorage.getItem("XppErrMessage"))
    );
    console.warn("错误信息", this.message.error);
  }
};
</script>

<style lang="scss" scoped>
#errorContainer {
  height: 100vh;
  background: #2f2f2f url(/img/404.png) right top no-repeat;
  background-size: 100vh;
}
.xpp-error-404 {
  max-width: 1170px;
  margin: 220px auto 0;
  h2 {
    font-size: 48px;
    color: #fff;
    letter-spacing: 1px;
    height: 67px;
    line-height: 67px;
  }
  p {
    font-size: 20px;
    color: #aaa;
    letter-spacing: 0;
    line-height: 40px;
    margin-top: 11px;
    margin-bottom: 60px;
  }
}
</style>
