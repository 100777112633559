import { render, staticRenderFns } from "./PowerHead.vue?vue&type=template&id=bf1c08a2&scoped=true&"
import script from "./PowerHead.vue?vue&type=script&lang=js&"
export * from "./PowerHead.vue?vue&type=script&lang=js&"
import style0 from "./PowerHead.vue?vue&type=style&index=0&id=bf1c08a2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf1c08a2",
  null
  
)

export default component.exports