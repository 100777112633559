<template>
  <div id="errlogContainer">
    <div class="table-log">
      <el-table :data="list" style="width: 100%;" height="100%">
        <el-table-column
          fixed
          prop="groupId"
          label="id"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="userId"
          label="user"
          width="100"
        ></el-table-column>
        <el-table-column prop="time" label="时间" width="100"></el-table-column>
        <el-table-column label="message">
          <template slot-scope="scope">
            <div v-if="scope.row.errMsg.type === 'error'">
              {{ scope.row.errMsg.stack }}
            </div>
            <div v-else>
              <p>{{ scope.row.errMsg.message }}</p>
              <pre>{{ scope.row.errMsg.info }}</pre>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-pagination
      background
      layout="prev, pager, next"
      :pageSize="30"
      @current-change="currentChange"
      :total="1000"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "ErrorLog",
  data() {
    return {
      pageNum: 1,
      list: []
    };
  },
  mounted() {
    this.updataList();
  },
  methods: {
    async updataList(pageNum) {
      let { list } = await this.$axios.get("/index/extend/getErrorList", {
        params: {
          pageNum,
          pageSize: 30
        }
      });
      this.list = list;
    },
    currentChange(page) {
      this.updataList(page);
    }
  }
};
</script>
<style lang="scss" scoped>
.table-log {
  height: calc(100vh - 40px);
  overflow: auto;
}
.el-pagination {
  width: 400px;
  margin: 4px auto;
}
</style>
